.card-preview{
    padding: 5px;
    margin-top: 10px;
    height: 130px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    span{
        margin-bottom: 0;
        small{
            color: #8f8d8e;
        }
        h5{
            small{
                color: #8f8d8e;
            }
        }
    }
}

.card-list{
    padding: 5px;
    margin-top: 10px;
    span{
        margin-bottom: 0;
    }
    small{
        color: #8f8d8e;
    }
}

.list-comments{
    height: 70vh;
    overflow-y: scroll;
}

.more-informations strong{
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.close-button-comments{
    width:20px;
    transition: all ease .2s;
    border-radius: 5px;
    &:hover{
        background-color: #e9e9e9;
        cursor: pointer;
    }
}
@media screen and (max-width:  990px) {
    .list-comments{
        height: unset;
        overflow-y: unset;
    }
}