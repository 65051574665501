.footerCol {
    margin-top: 50px;
    color: #C4C4C4;
    min-height: 200px;
}

.footer {
    background-color: black;
    justify-content: center;
    width: 100%;
}

.footerInfo {
    margin-top: 2vh;

    p {
        font-size: .7rem;
    }
}

.borderRight {
    border-right: 1px solid white;
}

.borderRightCenter {
    border-right: 1px solid white;
}

.footerTitle {
    margin-top: 2vh;
}

.whiteLink {
    color: white !important;

    p {
        margin-bottom: .6rem;
        font-size: .8em;
    }
}

.copyright {
    color: white;
    margin-top: 3vh;
}

@media screen and (min-width: 990px) and (max-width: 1200px) {
    .borderRightCenter {
        border-right: unset;
    }
}

//  Mobile & tablets

@media screen and (max-width: 1000px) {
    .footerCols {
        text-align: center;
    }

    h5 {
        font-size: 1.6rem;
    }

    .Adresse {
        flex-direction: column;

        svg {
            position: unset;
            left: unset;
            margin: unset;
        }

        p {
            width: unset;
            margin-left: unset;
        }
    }

    .Contact {
        flex-direction: column;

        svg {
            position: unset;
            left: unset;
            margin: unset;

        }

        p {
            width: unset;
            margin-left: unset;
            margin-top: unset !important;
        }
    }

    .footerInfo {
        margin-top: 2vh;
        text-align: center;
        svg {
            margin-bottom: 2vh;
        }
    }

    .footerTitle {
        margin-top: unset;
    }

    .separatorFooter {
        border: 0.5px solid white;
        width: 5em;
        margin: 2vh 0 2vh 0;
    }

    .whiteLink p {
        display: none;
        margin: 0;
    }

    .footerCol {
        margin-top: unset;
        min-height: unset;
    }

    .conditions {
        font-size: 0.7rem;
    }

    .footerInfo {
        p {
            font-size: 1rem;
        }
    }

    .footer {
        padding-bottom: 120px
    }

}