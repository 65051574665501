
// Expand
.ListExtendsCollapse{
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
}

.ListExtendsUncollapse{
    height: auto;
    margin: 2vh;
    padding: 2vh;
    overflow: auto;
    border-top: 1px solid #dee2e6;
}

.CreateExtendsCollapse{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    transition: all .5s ease;
}

.CreateExtendsUncollapse{
    height: 300px;
    margin: 2vh;
    padding: 2vh;
    border-top: 1px solid #dee2e6;
}

.CreateExtendsCollapseInput{
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1vh;
}

.SimulatedExtendsCollapse{
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
}

.SimulatedExtendsUncollapse{
    height: auto;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .CreateExtendsCollapseInput{
        width: 100%;
    }

    .SimulatedExtendsUncollapse{
        height: 500px;
    }
}

