.apartment-list-image{
    height: 30vh !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.divAddFavResult{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    z-index: 5;
    height: 40px;
    width: 40px;
}

.addFavResult{
    cursor: pointer;
    height: 30px;
    width: 30px;
    transition: all ease .5s;
}

.addFavResult:hover{
    height: 40px;
    width: 40px;
    path{
        fill: #B7A57E;
    }
} 

.search-map-fixed-result{
    height: 90vh;
    position: sticky;
    position: -webkit-sticky;
    top: 7vh;
}

.search-map-unique{
    height: 400px;
}

.knowPlus{
    position: absolute;
    bottom: 0;
    right: .5vh;
}

input.searchbarComponent::placeholder {
    color:black !important; 
    font-size: 1rem;
}

.resultContent{
    width: 100%; 
}

.apart-marker{
    height: 25px;
    width: auto;
    color: black;
}

.beachResultHeader{
    width: 100%;
    height: 350px;
}

.beachResultHeader h1{
    display: flex;
    justify-content: center;
    position: relative;
    top: 10vh;
    color: white;
    font-weight: 900;
}

h3{
    font-weight: 900;
}

svg{
    color: #B7A57E;
}

.btn-secondary-custom{
    text-transform: uppercase ;
    display: flex;
    align-items: center;
    border-radius: unset;
    border: unset;
    background-color: #B7A57E !important;
    color: white;
}

.btn-Content{
    background-color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    padding: 3vh;
    h4{
        color: white;
        font-weight: 900;
        width: 100%;
    }
}

.btn-contact-result{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1vh;
    margin: 1vh;
    border-radius: 5px;
    color: white !important;
}

input::placeholder{
    color: white;
    font-weight: 700;
    font-size: 14px;
}

@media screen and (max-width: 1200px){
    .knowPlus{
        position: unset;
    }
}

@media screen and (max-width: 992px){
    .knowPlus{
        position: unset;
    }
    .card-search-title h2{
        font-size: 2rem;
    }
}

@media screen and (max-width: 767px){
    .search-map-disapear{
        display:none;
    }
    .resultContent{
        height: 100%;
    }
    .card-search-title h2{
        font-size: 1.5rem;
    }
}