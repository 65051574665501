.searchbar{
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0px 11px 11px #00000029;
}

.displaySearchBarHome{
    z-index: 10;
    width: 80%;
    left: 10vw;
    right: 10vw;
    position: relative;
    top: -50px;
}

.displaySearchBarResult{
    z-index: 10;
    width: 80%;
    left: 10vw;
    right: 10vw;
    position: relative;
    top: -30px;
}

.searchbarComponent{
    height: 100%;
    background-color: white;
    width: 100%;
    font: normal normal bold 1.5rem/1.5rem Montserrat;
    color: #8f8d8e;
    text-align: center;
}

.ColSearchBar{
    height: 100%;
    width: 100%;
    text-align: center;
}

.ColNbPerson{
    background-color: white;
    position: relative;

}

.ColNbPerson p{
    font: normal normal bold 22px/27px Montserrat;
    color: #8f8d8e;
}

.ColSearchButton{
    background-color: Black;
}

.relative{
    position: relative;
}

#searchbutton{
    width: 70%;
    height: 25%;
    background-color: #B7A57E;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.date-range-search-bar .row{
    height: 100%;
    input{
        border: none;
        color: black;
        cursor: pointer;
    }
}

.date-range-alone .row{
    height: 100%;
    input{
        border: none;
        color: black;
        cursor: pointer;
    }
}


.dateRelative{
    position: relative;
}

.dateAbsolu{
    position: absolute; 
    p{
        font: normal normal bold 22px/27px Montserrat;
        color: #8f8d8e;
    }
}

.backgroundTrans{
    background-color: transparent;
    padding-top: 5vh;
    font-size: 1.7rem;
    font-weight: 900;
}

.backgroundDate{
    padding-left: 5vh;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.input-text-right-to-xxl-center{
    text-align: center !important;
}

.person-svg{
    position: absolute;
    left: calc(100% - 90px);
    top: calc(65% - 45px);
    bottom: calc(50% - 45px);
}

.separatorSearchBar{
    margin-top: 20px;
    width: 90%;
}

// Mobile & tablets

@media (max-width:1199px){
    .person-svg{
        top: calc(50% - 30px);
        svg{
            width: 60px;
        }
    }

    .dateAbsolu svg{
        margin-left: auto;
        margin-right: 5vw;
    }

    .ColSearchButton{
        height: 150px;
    }

    .ColNbPerson{
        height: 100px;
        box-shadow: 0px 11px 11px #00000029;
        p{
            font-size: 13px;
            text-transform: uppercase;
            margin-left: -2vw;
        }
    }

    #searchbutton{
        height: 30%;
        text-transform: uppercase;
        
    }

    .nbPersonIconResp{
        position: absolute;
        left: 39vw;
        bottom: 7vh;
        height: 35px;
    }

    .nbPersonIcon{
        height: 80%;
    }
}
@media (min-width:1200px){
    .input-text-right-to-xxl-center{
        text-align: right !important;
    }
}
@media (min-width:1500px){
    .input-text-right-to-xxl-center{
        text-align: center !important;
    }
}

