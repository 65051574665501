.shadow-advantage {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        margin: 20px;
    }
}

.big-button{
    min-height: 70px;
    background-color: #B7A57E !important;
    color: white !important;
    font-size: 1.5rem;
    font-weight: 700;
}

.step-content-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    min-height: 150px;
    border-bottom: solid 2px #b7a57e;
    width: 100%;
    p{
        margin: unset;
        strong a{
            text-decoration: underline;
            color: #b7a57e;
            &:hover{
                color: #b7a57e;
                -webkit-filter:brightness(80%);
            }
        }
    }
}

.step-content-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    min-height: 150px;
    border-bottom: solid 2px #b7a57e;
    width: 100%;
    p{
        margin: unset;
    }
}

.step-number {
    font-size: 5rem;
    padding: 10px;
    margin: unset;
}

.step-name {
    font-size: 1.5rem;
}

.step{
    width: 50%;
}

@media screen and (max-width: 1100px) { 
    .step{
        width: 100%;
    }

    .noDisplayOwner{
        display: none;
    }
}
