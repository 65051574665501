.scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 1rem 0;
  width: 100%;
  overflow: hidden;
}

.homepage-title{
    color: black;
    font-weight:bolder;
    font-size: 3em;
    text-align: center;
    margin-bottom: 3vh;
  }

.separator{
  border: 1px solid #B7A57E;
  color: #b7a57e;
  width: 15%;
  margin: auto;
}

.container {
  max-width: 80vw !important;
}

.arrowHome{
  cursor: pointer;
} 
