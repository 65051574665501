.slider-left {
    position: absolute;
    width: calc(100% - 100px);
    height: 92vh;
    margin-top: 8vh;
    opacity: 0;
    transform: translateX(200%);
    -webkit-transform: translateX(200%);
    -webkit-transition: opacity .6s;
    transition: opacity .6s;
}

.slide-in {
    animation: slide-in 1s forwards;
    -webkit-animation: slide-in 1s forwards;
    filter: alpha(opacity=50);
        opacity: 1;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -ms-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out;
}

.slide-out {
    animation: slide-out 1s forwards;
    -webkit-animation: slide-out 1s forwards;
    filter: alpha(opacity=50);
        opacity: 0;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -ms-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out;
}
    
@keyframes slide-in {
    0% { transform: translateX(-92%);
        transform: opacity 0; }
    100% { transform: translateX(80px); 
        transform: opacity 1;}
}

@-webkit-keyframes slide-in {
    0% { -webkit-transform: translateX(-100%);
        -webkit-transform: opacity 0; }
    100% { -webkit-transform: translateX(80px); 
        -webkit-transform: opacity 1;}
}
    
@keyframes slide-out {
    0% { transform: translateX(0%);
        transform: opacity 1; }
    100% { transform: translateX(-100%); 
        transform: opacity 0;}
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%);
        transform: opacity 1; }
    100% { -webkit-transform: translateX(-100%); 
        transform: opacity 0;}
}
.hideScroll::-webkit-scrollbar {
    display: none;
  }
  .hideScroll {
    scrollbar-width: none;
  }
  @media screen and (max-width: 600px) {
    .slider-left {
        position: absolute;
        width: calc(100% - 80px);
        height: 92vh;
        margin-top: 8vh;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -webkit-transition: opacity .6s;
        transition: opacity .6s;
    }
    @keyframes slide-in {
        100% { transform: translateX(70px);
                transform: opacity 1; }
    }
    
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(70px); 
            transform: opacity 1;}
    }
  }
  @media screen and (max-width: 500px) {
    .slider-left {
        position: absolute;
        width: calc(100% - 80px);
        height: 92vh;
        margin-top: 8vh;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -webkit-transition: opacity .6s;
        transition: opacity .6s;
    }
    @keyframes slide-in {
        100% { transform: translateX(70px);
                transform: opacity 1; }
    }
    
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(70px); 
            transform: opacity 1;}
    }
  }
