.commentage {
    min-height: 70vh;
}

.absoluteOverStar {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    cursor: pointer;
    transition: all ease .2s;
}

.overStar {
    svg {
        transition: all ease .2s;
    }
}

.overStar:hover {
    .absoluteOverStar {
        height: 105%;
        width: 105%;
    }

    svg {
        height: 105%;
        width: 105%;
    }
}

.comment-gray-separator {
    border: 1px solid gray;
    color: gray;
    width: 60%;
    margin-bottom: 20px;
    margin-top: 20px
}