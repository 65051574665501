.alerter{
    width: 25vw;
    border-radius: 25px;
    padding: 25px;
}         

.alert-global{
    position: fixed !important;
    z-index: 50000 !important;
    animation: 4.4s ease-in-out enter-leave;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px; 
    top: 11vh;
    @keyframes enter-leave {
        0% {
            right: -25vw;
            opacity: 0;
        }
        10% {
            right: 50px;
            opacity: 1;
        }
        80% {
            right: 50px;
            opacity: 1;
        }
        90% {
            right: -25vw;
        }
        100% {
            opacity: 0;
        }
    }
}

.alert-global p {
    margin: auto;
}