.optionVisible{
    display: block;
}

.optionInvisible{
    display: none;
}

.animDown{
    transform: rotateZ(0deg);
}

.animUp{
    transform: rotateZ(180deg);
}

.arrow{
    transition: all 0.4s ease;
}

.optionNbPerson:hover{
    background-color: lightgray;
}

.listNbPerson{
    height: 300px;
    overflow-y: scroll;
    position: relative;
    list-style-type: none;
    bottom: 0;
    padding: 0;
    background-color: white;
    font-size: 1.3rem;
    text-align-last: center;
    z-index: 11;
    box-shadow: 0px 11px 11px #00000029;
    transition: all ease 1s;
    li{
        padding-bottom: 1vh;
    }
    div{
        color: black !important;
    }
}

.selectNbPerson{
    text-align: center;
    font-size: 2.7rem;
    font-weight: 900;
    width: 100%;
    cursor: pointer;
}

.optionNbPerson{
    text-align: center;
}