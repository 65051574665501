$secondary: #218EAE;
[data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    
    /* customizable */
    transition: all 0.15s ease;
    color: white;
    height: 100%;
    width: 150px;
    text-align: center;
}

[data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;
    
    /* customizable */
    background: #6c757d;
    margin-left: 65px;    
}

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}
.font-size{
    font-size: 1.75em;
}
.nav-style {
    position: fixed;
    height: 100%;
    background: #6c757d;
}
//color of the sidebar items icon
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    color: white;
}
.sidenav-item {
    color: white;
}
.content-slider{
    display: flex;
    height: 100%;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.auto-margin{
    margin-right: auto;
    margin-left: auto;
}
.verticalAlign{
    height: 100%;
    align-items: center;
}
.size-oops{
    max-height: 30vh
}
.center{
    text-align: center;
}
.noReturn{
    white-space:nowrap !important; 
    text-overflow: ellipsis !important;
}
// buttons

.adminButton {
    border-radius: 5px;
}
.modalButton {
    padding: 1vh 1vw 1vh 1vw;
    margin-top: 2vh;
}
.adminButtonIcon{
    margin: 1vh 1vh 1vh 1vh;
}
a.home-nav{
    color:#F0D5D7 !important;
}