.invalid-div{
    height: 30px;
    width: 30px;
    background-color: rgb(164, 33, 65);
    border-radius: 10px;
}

.valid-div{
    height: 30px;
    width: 30px;
    background-color: orange;
    border-radius: 10px;
}

.payed-div{
    height: 30px;
    width: 30px;
    background-color: green;
    border-radius: 10px;
}