.create-width{
    width: 50%;
}
@media screen and (max-width: 700px) {
    .create-width{
        width: 70%;
    }
}
@media screen and (max-width: 500px) {
    .create-width{
        width: 90%;
    }
}
@media screen and (max-width: 400px) {
    .create-width{
        width: 100%;
    }
}