.informationCard{
    height: 28vh;
    width: 7vw;
    box-shadow: 0px 6px 6px #00000029;
    padding: 2vh;
    margin: 2vh;
    p{
        color: #8f8d8e;
        font: normal normal bold 25px/30px Montserrat;
        text-align: center;
        margin: auto; 
    }
    svg{
        height: 95px;
        width: 110px;
    }
}

.informations{
    justify-content: center;
    margin-bottom: 5vh;
    width: 80%;
    margin-left: 10% !important;
    margin-right: 10% !important;
}


@media screen and (max-width:1000px) {

    .informationCard{
        height: 15vh;
        padding: 1vh;
        margin: auto;
        margin-bottom: 2vh;
        p{
            font-size: 10px;
            font: normal normal bold 10px/15px Montserrat;
        }

        svg{
            height: 45px;
        }

    .Opinion{
        margin-top: 10vh ;
    }    
        
    }
}