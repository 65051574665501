$primary: #A42141;
$secondary: #218EAE;
$sucess: #19C1A8;
$error: #E30910;

button#button-left.dropdown-toggle.btn.btn-outline-secondary {
  z-index: 2000 !important;
}

.homepage-title.service {
  margin-bottom: 10vh;
}

.homepage-title.vision {
  top: -10vh !important;
}

.nice-dates-popover {
  z-index: 150 !important;
  width: calc(100% - 2rem) !important;
  left: 1rem;
}

.carousel-control-prev{
  display: none;
}

.carousel-control-next{
  display: none;
}

// modif de la taille des dates d'arrivée et de départ 
.date-range-search-bar {
  height: 25vh;
  background-color: white;
}

.date-range-alone {
  height: 150px;
  background-color: white;
}

.dateRangeBoxShadow{
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

//Caroussel
.carousel {
  margin-bottom: 20px !important;
}

.carousselImage {
  max-width: 100%;
  height: 80vh;
  object-fit: cover;
}

//Homelinks

a,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

a.navItems,
a.navItems:hover,
a.navItems:visited,
a.navItems:link,
a.navItems:active {
  color: black;
  text-decoration: none;
}

.end-container {
  margin-bottom: 50px;
}

.home-title {
  font-weight: bold !important;
  z-index: 10 !important;
  position: absolute !important;
  color: white !important;
  font-weight: bolder;
  text-align: center;
  height: 70vh;
  display: flex;
  align-items: center;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}

.home-title h1 {
  width: 100%;
  font-size: 4rem;
  display: flex;
  flex-direction: column;
}

.carousel-item {
  transition: opacity 1s ease-in-out !important;
}

li {
  transition: opacity 1s ease !important;
}

//For Mobile 

@media screen and (max-width:1000px) {

  .home-title {
    height: unset;
  }

  .backgroundDate {
    height: 90%;
    padding-left: 0;
  }

  .dateRangeFontSizeAdmin {
    font-size: 1rem !important;
  }

  .dateAbsolu p {
    text-transform: uppercase;
    font-size: 15px;
  }

  .home-title h1 {
    font-size: 1.3rem;
    font-weight: 900;
    position: relative;
    bottom: 25vh;
    text-transform: uppercase;
  }

  .carousel-inner {
    margin-top: 3vh;
    height: 25vh;
  }

  .carousel-item {
    height: 100%;
  }

  .date-range-search-bar {
    height: 250px;
  }

  .date-range-alone {
    height: 150px;
  }

  .carousselImage {
    max-width: 100%;
    height: 100%;
  }
}