.object-item {
    width: calc(100% / 3);
    flex: 0 0 auto;
    padding: 10px;
    transition: transform 0.5s ease-in-out;
}

.selectionContentCard {
    position: relative;
    width: calc(100% - 30px);
    top: -10%;
    left: 15px;
}

.selectionCard img {
    width: 100%;
    height: 250px;
}

.shortDescriptionSelection {
    height: 250px;
    background-color: white;
    padding: 3vh 1vh 0vh 1vh;
    box-shadow: lightgray 0px 1px 6px, lightgray 0px 1px 6px;
}

.selectionCard p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.discoverButton {
    position: absolute;
    bottom: -5%;
    right: 5%;
    width: 70%;
    background-color: #B7A57E;
    color: white;
    font-weight: 700;
    height: 30px;
}

.apartTitleSelection{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

@media screen and (max-width:1200px) {
    .object-item {
        width: calc(100% / 2);
    }
}

@media screen and (max-width:700px){
    .object-item {
        width: 100%;
    }
    .discoverButton {
        right: 5%;
        width: 90%;
        height: 40px;
    }
}