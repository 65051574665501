.person {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .content-team {
    border-radius: 50%;
    height: 312px;
    -webkit-tap-highlight-color: transparent;
    transform: scale(0.48);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 410px;
  }
  .content-team:after {
    content: "";
    height: 10px;
    position: absolute;
    top: 390px;
    width: 100%;
  }
  .content-team:hover {
    transform: scale(0.54);
  }
  .content-team-inner {
    clip-path: path(
      "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"
    );
    position: relative;
    transform-origin: 50%;
    top: -200px;
  }
  .circle {
    border-radius: 50%;
    cursor: pointer;
    height: 390px;
    left: 10px;
    pointer-events: none;
    position: absolute;
    background-color: white;
    border: solid #b8a680 2px;
    top: 210px;
    width: 380px;
  }
  .img {
    pointer-events: none;
    position: relative;
    transform: translateY(20px) scale(1.15);
    transform-origin: 50% bottom;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .content:hover .img {
    transform: translateY(0) scale(1.2);
  }
  .img1 {
    left: 22px;
    top: 130px;
    width: 340px;
  }
  .img2 {
    left: 22px;
    top: 135px;
    width: 340px;
  }
  .img3 {
    left: -16px;
    top: 120px;
    width: 340px;
  }
  .tristan{
    left: 30px;
    top: 120px;
    width: 340px;
  }

  .diana{
    left: 22px;
    top: 80px;
    width: 340px;
  }

  .berengere{
    left: 50px;
    top: 140px;
    width: 300px;
  }

  .divider {
    background-color: #B7A57E;
    height: 1px;
    width: 160px;
  }
  .name {
    color: #404245;
    font-size: 36px;
    font-weight: 600;
    margin-top: 16px;
    text-align: center;
  }
  .title {
    color: #6e6e6e;
    font-family: arial;
    font-size: 14px;
    font-style: italic;
    margin-top: 4px;
  }