.imgHover:hover{
    cursor: pointer;
}

.carousel-img-preview-hover{
    transition: transform .2s;
    &:hover{
        -ms-transform: scale(1.02);
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }
}

.imgPreviewHover{
    max-height: 300px;
    transition: transform .2s;
    &:hover{
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        transform-origin: bottom;
        z-index: 2;
    }
}

@media (max-width:1200px){
    .imgPreviewHover{
        transition: unset;
        &:hover{
            transform: unset;
            -ms-transform: unset;
            -webkit-transform: unset;
            z-index: unset;
        }
    }
}