.article-card-preview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    margin-top: 10px;
    height: 300px;
    p{
        display: -webkit-box;
        max-width: 70%;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.article-shadow{
    box-shadow: rgba(27, 31, 35, 0.04) 0px 2px 0px, rgba(255, 255, 255, 0.25) 0px 2px 0px inset;
}

.dancing-script-text{
    a{
        font-family: 'Dancing Script', cursive;
        font-size: 1.7rem;
        text-decoration: underline;
        color: #b7a57e;
    }
    a:hover{
        -webkit-filter:brightness(80%);
    }
    h2{
        font-family: 'Dancing Script', cursive;
        font-size: 3rem !important;
    }
}

.golden{
    color: #b7a57e;
    &:hover{
        color: #b7a57e;
        -webkit-filter:brightness(80%);
    }
}

@media screen and (max-width: 990px) {
    .article-card-preview{
        p{
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 700px) {
    .article-card-preview{
        height: 350px;
    }
}