.enregistrement{
    min-height: 70vh;
}

.register-separator{
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, transparent, #CCC, transparent);
}

.zebra{
    background-image: linear-gradient(to right, transparent, rgb(244, 244, 244), transparent);
}

@media screen and (max-width: 991px) {
    .enregistrement{
        min-height: 100vh;
    }
}