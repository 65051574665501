.cardTexts{
    text-align: center;
    margin-bottom: 1px !important;
}

.page-item.active .page-link{
    background-color: #B7A57E !important;
    border-color: #B7A57E;
}

.RoomDetails div{
    .input-group-prepend{
        min-width: 160px;
        span{
            width: 100%;
        }
    }
    input{
        max-width: 100px;
    }
}

.nav-link{
    height: 100% !important;
}

.navbar{
    padding: 0;
    border-radius: 5px;
    height: 100%;
}

.navbar-nav{
    height: 100%;
}

.navbar-collapse{
    height: 100%;
}
                   
.apartmentCardBody{
    padding: 1vh !important;
}

.navLinkActive.active{
    background-color: white;
}

.navLinkActive{
    background-color: #ECECEC;
    border: solid 1px;
    text-align: center;
}

.apartment-admin-button{
    margin-top: 10px;
}

.adminDateRange{
    width: 100% !important;
    height: 40px;
    text-align: center;
    border-radius: 5px !important;
}

@media screen and (max-width: 990px) {
    .navbar-collapse{
        box-shadow: 0px 11px 11px #00000029;
    }
}
