.preview_wrap {
    width: 100%;
    height: 300px;
    padding: 0;
    overflow: hidden;
    justify-content: center;
    display: flex;
}
.frame_wrap {
    width: 2000px;
    height: 786px;
    border: 0;
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform: scale(0.6);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: top center;
}

@media (max-width: 1450px){
    .frame_wrap {
        -ms-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -o-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}